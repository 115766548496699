import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 {...{
      "id": "my-used3-hook"
    }}>{`my useD3 hook`}</h2>
    <p>{`Remember when we talked about D3 blackbox rendering? I built a hook for that so
you don't have to mess around with HOCs anymore :)`}</p>
    <p>{`Read the full docs at `}<a parentName="p" {...{
        "href": "https://d3blackbox.com/"
      }}>{`d3blackbox.com`}</a></p>
    <p>{`It works as a combination of `}<inlineCode parentName="p">{`useRef`}</inlineCode>{` and `}<inlineCode parentName="p">{`useEffect`}</inlineCode>{`. Hooks into component
re-renders, gives you control of the anchor element, and re-runs your D3 render
function on every component render.`}</p>
    <p>{`You use it like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import { useD3 } from "d3blackbox";
function renderSomeD3(anchor) {
    d3.select(anchor);
    // ...
}

const MyD3Component = ({ x, y }) => {
    const refAnchor = useD3(anchor => renderSomeD3(anchor));
    return <g ref={refAnchor} transform={\`translate(\${x}, \${y})\`} />;
};
`}</code></pre>
    <p>{`You'll see how this works in more detail when we refactor the big example to
hooks. We'll use `}<inlineCode parentName="p">{`useD3`}</inlineCode>{` to build axes.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      